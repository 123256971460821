<template>
  <DxDataGrid
    :data-source="historyStore"
    :show-borders="true"
    :show-column-lines="true"
    :columns="columns"
    :columnAutoWidth="true"
    @cellDblClick="onDblClick"
  >
    <DxSorting mode="none" />

    <template #text-area-template="{ data }">
      <pre>{{ data.value }}</pre>
    </template>
  </DxDataGrid>
</template>

<script>
import { DxDataGrid, DxSorting } from 'devextreme-vue/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import store from '../../store';
import fn from '../../utils/function';

export default {
  components: {
    DxDataGrid,
    DxSorting,
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    historyStore() {
      return this.masterDetailData.key === null
        ? null
        : {
            store: createStore({
              key: this.keyName,
              loadUrl: this.url,
              loadParams: {
                key: this.masterDetailData.key,
                key1: this.key1,
                key2: this.key2,
                key3: this.key3,
                hospitalCodeManager: store.getters.getHospitalCode,
              },
              onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.headers = {
                  token: store.state.token,
                  userId: store.state.userId,
                };
              },
            }),
          };
    },
  },
  methods: {
    onDblClick(e) {
      fn.downLoadFile(e);
    },
  },
};
</script>

<style scoped>
.status-icon-cell {
  height: 25px;
  display: inline-block;
  margin-right: 8px;
}

.middle {
  vertical-align: middle;
}
</style>
