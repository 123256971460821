<template>
  <DxScrollView
    v-if="beforeReq"
    height="100%"
    width="100%"
    class="with-footer single-card"
  >
    <div class="dx-card content">
      <div class="header">
        <img class="logo" :src="require('@/assets/ci.png')" />
      </div>
      <div class="notice">
        <b>개인정보 활용 동의</b>
        <br /><br />
        <div class="text">
          개인정보 활용 동의 및 제3자정보제공동의 확인 후 간편 인증을 통해
          개인정보 활용에 동의 합니다.
        </div>
      </div>

      <div class="reqBtn">
        <DxButton
          class="btn"
          :icon="ivIcon"
          text="개인정보 활용 동의(필수)"
          @click="showIv"
          styling-mode="outlined"
        />
      </div>

      <div class="thirdBtn">
        <DxButton
          class="btn"
          :icon="thirdIcon"
          text="제3자정보제공동의(필수)"
          @click="showThird"
          styling-mode="outlined"
        />
      </div>

      <div class="snsCert">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image.src"
          :class="{ highlighted: selectedImage === index }"
          @click="onClickCertType(index)"
          class="snsIcon"
        />
      </div>

      <div class="reqBtn">
        <DxButton
          class="btn"
          :disabled="disabledBtnReqIv"
          :text="btnName"
          @click="reqCert"
          styling-mode="outlined"
        />
      </div>
    </div>
    <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="false"
      :show-title="true"
      :title="popupTitle"
      width="100%"
      height="100%"
    >
      <DxScrollView v-if="beforeReq" height="100%" width="100%">
        <VuePdfEmbed :width="pdfWidth" source="/iv.pdf" />
        <div class="reqBtn">
          <DxButton
            class="btnRedIv"
            text="모든 내용을 읽고 확인하였습니다."
            @click="red('IV')"
            type="success"
            styling-mode="contained"
          />
        </div>
      </DxScrollView>
    </DxPopup>

    <DxPopup
      v-model:visible="popupThirdVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="false"
      :show-title="true"
      :title="popupThirdTitle"
      width="100%"
      height="100%"
    >
      <DxScrollView v-if="beforeReq" height="100%" width="100%">
        <VuePdfEmbed :width="pdfWidth" source="/third.pdf" />
        <div class="reqBtn">
          <DxButton
            class="btnRedIv"
            text="모든 내용을 읽고 확인하였습니다."
            @click="red('THIRD')"
            type="success"
            styling-mode="contained"
          />
        </div>
      </DxScrollView>
    </DxPopup>
  </DxScrollView>

  <DxScrollView
    v-if="afterReq"
    height="100%"
    width="100%"
    class="with-footer single-card"
  >
    <div class="dx-card content">
      <div class="header">
        <img class="logo" :src="require('@/assets/ci.png')" />
      </div>
      <div class="notice">
        <b>개인정보 활용 동의</b>
        <br /><br />
        <div class="passKeyContainer" v-if="certType === 'SMS'">
          <div class="text">
            문자로 인증번호가 발송되었습니다. 인증번호 입력 후 확인 버튼을
            눌러주세요.
          </div>
          <br />
          <br />
          <DxTextBox
            width="90%"
            v-model:value="passKey"
            styling-mode="filled"
            :input-attr="{ autocomplete: 'one-time-code' }"
          >
            <DxTextBoxButton
              name="clock"
              :options="{ icon: 'clock', text: remainTime }"
              location="after"
            />
          </DxTextBox>
        </div>
        <div v-else class="text">
          인증 요청이 완료되었습니다. {{ this.certName }} 앱에서 인증 완료 후
          확인 버튼을 눌러주세요.
        </div>
        <br /><br />
      </div>
      <div class="reqBtn">
        <DxButton
          class="btn"
          text="확인"
          @click="confirmCert"
          styling-mode="outlined"
        />
      </div>
    </div>
  </DxScrollView>

  <DxScrollView
    v-if="incorrectUrl"
    height="100%"
    width="100%"
    class="with-footer single-card"
  >
    <div class="dx-card content">
      <div class="header">
        <img class="logo" :src="require('@/assets/ci.png')" />
      </div>
      <div class="notice">
        <b>{{ incorrectTitle }}</b>
        <br /><br />
        <div class="text">
          {{ incorrectContents }}
        </div>
        <br /><br />
      </div>
    </div>
  </DxScrollView>

  <DxScrollView
    v-if="succesCert"
    height="100%"
    width="100%"
    class="with-footer single-card"
  >
    <div class="dx-card content">
      <div class="header">
        <img class="logo" :src="require('@/assets/ci.png')" />
      </div>
      <div class="notice">
        <b>개인정보 활용 동의 결과</b>
        <br /><br />
        <div class="text">개인정보 활용 동의가 완료 되었습니다.</div>
        <br /><br />
      </div>
    </div>
  </DxScrollView>
</template>

<script>
import DxScrollView from 'devextreme-vue/scroll-view';
import DxButton from 'devextreme-vue/button';
import {
  DxTextBox,
  DxButton as DxTextBoxButton,
} from 'devextreme-vue/text-box';
import { DxPopup } from 'devextreme-vue/popup';
import VuePdfEmbed from 'vue-pdf-embed';
import dsu from '@/utils/dataSourceUtils';
import fn from '@/utils/function';

export default {
  components: {
    DxScrollView,
    DxButton,
    DxTextBox,
    DxTextBoxButton,
    VuePdfEmbed,
    DxPopup,
  },
  data() {
    return {
      url: '',
      certType: '',
      certName: '',
      btnName: '',
      beforeReq: false,
      afterReq: false,
      incorrectUrl: false,
      succesCert: false,
      reqId: '',
      selectedImage: null,
      selectedPtUrl: '',
      images: [
        { src: require('@/assets/sms.png'), certType: 'SMS' },
        { src: require('@/assets/kakao.jpg'), certType: 'KAKAO' },
        { src: require('@/assets/naver.jpg'), certType: 'NAVER' },
        { src: require('@/assets/pass.jpg'), certType: 'PASS' },
      ],
      passKey: '',
      totalTime: 0,
      remainTime: '',
      timer: undefined,
      incorrectTitle: '',
      incorrectContents: '',
      pdfData: undefined,
      pdfPages: undefined,
      popupVisible: false,
      popupThirdVisible: false,
      popupTitle: '',
      popupThirdTitle: '',
      pdfWidth: window.innerWidth,
      ivIcon: '',
      thirdIcon: '',
      disabledBtnReqIv: true,
    };
  },
  beforeMount() {
    this.url = this.$route.query.z;
    this.onClickCertType(0);

    if (this.url) {
      var params = { url: this.url };
      dsu.getAxiosOBJPost('/api/selectPtInfoPublic', params).then((res) => {
        let response = res.data;
        if (response.result == 'SUCCESS') {
          this.changeStatus('BEFORE_REQ');
        } else {
          if (response.data == 'alreadyCertificated') {
            this.changeStatus(
              'INCORRECT_URL',
              '개인정보 활용 동의 결과',
              '이미 개인정보 활용 동의가 완료되었습니다'
            );
            ('INCORRECT_URL');
          } else {
            this.changeStatus('INCORRECT_URL');
          }
        }
      });
    } else {
      this.changeStatus('INCORRECT_URL');
      return;
    }
  },
  methods: {
    onClickCertType(index) {
      this.selectedImage = index;
      this.certType = this.images[index].certType;
      this.setReqBtnValue();
    },
    setReqBtnValue() {
      if (this.certType == 'KAKAO') {
        this.certName = 'Kakao';
      } else if (this.certType == 'NAVER') {
        this.certName = 'Naver';
      } else if (this.certType == 'PASS') {
        this.certName = 'Pass';
      } else if (this.certType == 'SMS') {
        this.certName = '문자';
      }

      this.btnName = this.certName + '로 본인인증 요청';
    },
    changeStatus(status, title, contents) {
      if (status == 'BEFORE_REQ') {
        this.beforeReq = true;
        this.afterReq = false;
        this.incorrectUrl = false;
        this.succesCert = false;

        this.disabledBtnReqIv = true;
      } else if (status == 'AFTER_REQ') {
        this.beforeReq = false;
        this.afterReq = true;
        this.incorrectUrl = false;
        this.succesCert = false;
        if (this.certType == 'SMS') {
          this.startTimer();
        }
      } else if (status == 'INCORRECT_URL') {
        this.beforeReq = false;
        this.afterReq = false;
        this.incorrectUrl = true;
        this.succesCert = false;

        if (title && contents) {
          this.incorrectTitle = title;
          this.incorrectContents = contents;
        } else {
          this.incorrectTitle = '잘못된 접근 입니다.';
          this.incorrectContents = 'URL 확인 후 다시 시도해 주세요.';
        }
      } else if (status == 'SUCESS_CERT') {
        this.beforeReq = false;
        this.afterReq = false;
        this.incorrectUrl = false;
        this.succesCert = true;
      } else if (status == 'CLEAR') {
        this.beforeReq = false;
        this.afterReq = false;
        this.incorrectUrl = false;
        this.succesCert = false;

        this.disabledBtnReqIv = true;
      }
    },
    startTimer() {
      this.totalTime = 5 * 60;
      this.passKey = '';
      this.setTime();
      this.timer = setInterval(() => {
        if (this.totalTime > 0) {
          this.totalTime--;
          this.setTime();
        } else {
          this.remainTime = '시간 초과';
          clearInterval(this.timer);
        }
      }, 1000);
    },
    setTime() {
      var minutes = Math.floor(this.totalTime / 60);
      var seconds = this.totalTime % 60;
      this.remainTime = `${minutes < 10 ? '0' : ''}${minutes} : ${
        seconds < 10 ? '0' : ''
      }${seconds}`;
    },
    clear() {
      this.changeStatus('CLEAR');
    },
    reqCert(e) {
      if (!this.url) {
        this.changeStatus('INCORRECT_URL');
        return;
      }
      e.component.option('disabled', true);

      var params = {};
      params.url = this.url;
      params.certType = this.certType;
      params.iType = this.iType;

      dsu.getAxiosOBJPost('/pri', params).then((res) => {
        let response = res.data;
        e.component.option('disabled', false);
        if (response.result == 'SUCCESS') {
          this.changeStatus('AFTER_REQ');
          this.reqId = response.data;
        } else {
          fn.alert(response.msg, '확인');
        }
      });
    },
    confirmCert(e) {
      e.component.option('disabled', true);
      var params = {};
      params.reqId = this.reqId;
      if (this.certType == 'SMS') {
        params.passKey = this.passKey;
        if (!params.passKey) {
          fn.alert('인증번호를 입력해 주세요.', '확인');
          e.component.option('disabled', false);
          return;
        }
      }
      dsu.getAxiosOBJPost('/pci', params).then((res) => {
        let response = res.data;
        e.component.option('disabled', false);
        if (response.result == 'SUCCESS') {
          this.changeStatus('SUCESS_CERT');
        } else {
          if (response.data == 'expire') {
            fn.alert(response.msg, '확인').then(() => {
              location.reload();
            });
          } else {
            fn.alert(response.msg, '확인');
          }
        }
      });
    },
    showIv() {
      this.popupVisible = true;
      this.popupTitle = '개인정보 활용 동의서';
    },
    showThird() {
      this.popupThirdVisible = true;
      this.popupThirdTitle = '제3자정보제공동의';
    },
    red(argType) {
      if (argType == 'IV') {
        this.ivIcon = 'check';
        this.popupVisible = false;
      } else if (argType == 'THIRD') {
        this.thirdIcon = 'check';
        this.popupThirdVisible = false;
      }

      if (this.ivIcon == 'check' && this.thirdIcon == 'check') {
        this.disabledBtnReqIv = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../themes/generated/variables.base.scss';
.notice {
  font-size: 17px;
  text-align: left;
  .text {
    text-indent: 0.4em;
  }
  .passKeyContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.snsCert {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .snsIcon {
    margin: 0 7px;
    width: 20%;
    border-radius: 15%;
  }
}

.highlighted {
  box-shadow: 0 0 10px 5px rgba(140, 140, 140, 0.5);
}

.reqBtn {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: center;
  .btn {
    width: 90%;
    height: 45px;
    font-size: 16px;
    font-weight: bold;
  }
  .btnRedIv {
    width: 90%;
    height: 45px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}
.thirdBtn {
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
  justify-content: center;
  .btn {
    width: 90%;
    height: 45px;
    font-size: 16px;
    font-weight: bold;
  }
  .btnRedIv {
    width: 90%;
    height: 45px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 400px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;
      text-align: center;

      .logo {
        width: 70%;
      }
    }
  }
}

.dx-popup-content {
  padding: 0px !important;
}

.dx-popup-content.dx-dialog-content {
  padding: 24px !important;
}
</style>
