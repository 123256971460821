<template>
  <DxDataGrid
    id="medicationGrid"
    :ref="gridRefName"
    :show-borders="true"
    :show-column-lines="true"
    :keyExpr="['dispenseDate', 'medicationCode']"
    :dataSource="masterDetailData"
    width="100%"
  >
    <DxSorting mode="single" />
    <DxColumn data-field="dispenseDate" caption="조제 날짜" sortOrder="desc" />
    <DxColumn data-field="medicationCode" caption="KD Code" />
    <DxColumn data-field="medicationName" caption="KD Name" />
    <DxColumn data-field="strength" caption="함량" :width="100" />
    <DxColumn data-field="singleDosage" caption="1회 투약량" :width="100" />
    <DxColumn data-field="dailyDosage" caption="1일 투약 횟수" :width="100" />
    <DxColumn
      data-field="totalDosageDays"
      caption="투약 일수"
      :cell-template="totalDosageDaysTemplate"
    />
    <DxColumn data-field="dispenseOrganization" caption="조제 기관" />
  </DxDataGrid>
</template>

<script>
import { DxDataGrid, DxColumn, DxSorting } from 'devextreme-vue/data-grid';
('devextreme-vue/number-box');

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSorting,
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { gridRefName: 'medicationGrid' };
  },
  methods: {
    totalDosageDaysTemplate(cellElement, cellInfo) {
      cellElement.innerText = `${cellInfo.value} 일`;
    },
  },
};
</script>

<style scoped></style>
