<template>
  <DxDataGrid
    :showColumnHeaders="false"
    :data-source="targetList"
    :show-borders="true"
    :show-column-lines="true"
    @toolbar-preparing="onToolbarPreparing($event)"
    @row-removed="onRowRemoved($event)"
  >
    <DxScrolling mode="infinite" />
    <DxSelection mode="none" />
    <DxEditing
      :allow-updating="false"
      :allow-adding="false"
      :allow-deleting="true"
    />

    <DxColumn type="buttons" width="40">
      <DxButton name="edit" />
    </DxColumn>

    <DxColumn data-field="symptom" cell-template="symptom-cell-template" />
    <template #symptom-cell-template="{ data }">
      <div class="symptomCode">{{ data.data.symptomCode }}</div>
      <div class="symptomName">{{ data.data.symptomNameKor }}</div>
      <div class="symptomName">{{ data.data.symptomNameEng }}</div>
    </template>
  </DxDataGrid>

  <DxPopup
    :ref="popupRefName"
    height="auto"
    width="90%"
    :hideOnParentScroll="false"
    :drag-enabled="false"
    :hideOnOutsideClick="true"
    :showTitle="true"
    :title="title"
  >
    <DxForm :ref="formRefName" label-mode="hidden">
      <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />

      <DxSimpleItem>
        <SI @setSymptom="setSymptom" />
      </DxSimpleItem>

      <DxSimpleItem>
        <div class="content-block dx-card responsive-paddings">
          <DxDataGrid
            :data-source="sourceList"
            :columnAutoWidth="true"
            :show-borders="true"
            :show-column-lines="true"
            key-expr="symptomCode"
          >
            <DxScrolling mode="infinite" />
            <DxSelection mode="single" />
            <DxEditing
              :allow-updating="false"
              :allow-adding="false"
              :allow-deleting="true"
              mode="popup"
            />

            <DxColumn type="buttons">
              <DxButton name="edit" />
            </DxColumn>

            <DxColumn data-field="symptomCode" caption="증상 코드"> </DxColumn>

            <DxColumn data-field="symptomNameKor" caption="증상(한글)">
            </DxColumn>

            <DxColumn data-field="symptomNameEng" caption="증상(영문)">
            </DxColumn>
          </DxDataGrid>
        </div>
      </DxSimpleItem>

      <DxEmptyItem />

      <DxGroupItem col-count="2">
        <DxButtonItem>
          <DxButtonOptions
            text="저장"
            width="100%"
            type="default"
            @click="save"
          >
          </DxButtonOptions>
        </DxButtonItem>

        <DxButtonItem>
          <DxButtonOptions
            text="취소"
            width="100%"
            type="normal"
            @click="cancel"
          >
          </DxButtonOptions>
        </DxButtonItem>
      </DxGroupItem>
    </DxForm>
  </DxPopup>
</template>
<script>
import {
  DxDataGrid,
  DxSelection,
  DxScrolling,
  DxColumn,
  DxEditing,
} from 'devextreme-vue/data-grid';
import DxPopup from 'devextreme-vue/popup';
import {
  DxForm,
  DxColCountByScreen,
  DxGroupItem,
  DxSimpleItem,
  DxButtonItem,
  DxButtonOptions,
  DxEmptyItem,
} from 'devextreme-vue/form';
import SI from '../_common/SYMPTOM_INFO';
import fn from '../../utils/function';

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn,
    DxEditing,
    DxPopup,
    DxForm,
    DxGroupItem,
    DxColCountByScreen,
    DxSimpleItem,
    DxButtonItem,
    DxButtonOptions,
    SI,
    DxEmptyItem,
  },
  props: {
    title: String,
    isViewMode: Boolean,
    index: Number,
  },
  mounted() {
    this.refreshTargetList();
  },
  data() {
    return {
      popupRefName: 'popup',
      formRefName: 'form',
      targetList: [],
      sourceList: [],
    };
  },
  watch: {
    symptomData() {
      this.refreshTargetList();
    },
  },
  methods: {
    refreshTargetList() {
      if (this.index != null && this.index != undefined) {
        this.$emit('getSymptomData', this.setTargetList, this.index);
      } else {
        this.$emit('getSymptomData', this.setTargetList);
      }
    },
    setTargetList(data) {
      this.targetList = JSON.parse(data);
    },
    onToolbarPreparing(e) {
      if (!this.isViewMode) {
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'edit',
            text: '수정',
            stylingMode: 'outlined',
            onClick: this.showModify.bind(this),
          },
        });
      }
    },
    showModify() {
      this.sourceList = JSON.parse(JSON.stringify(this.targetList));
      this.$refs[this.popupRefName].instance.show();
      this.$emit('getSourceSymptomData', this.setSymptomData);
    },
    setSymptomData(data) {
      if (this.targetList.length == 0) {
        if (data) {
          this.sourceList = JSON.parse(data);
        } else {
          this.sourceList = [];
        }
      }
    },
    save() {
      this.targetList = JSON.parse(JSON.stringify(this.sourceList));
      this.$refs[this.popupRefName].instance.hide();
      this.$emit(
        'setSymptomData',
        JSON.parse(JSON.stringify(this.sourceList)),
        this.index
      );
    },
    cancel() {
      this.$refs[this.popupRefName].instance.hide();
    },
    onRowRemoved() {
      this.$emit(
        'setSymptomData',
        JSON.parse(JSON.stringify(this.targetList)),
        this.index
      );
    },
    setSymptom(symptom) {
      var isDup = this.sourceList.find(
        (row) => JSON.stringify(row) == JSON.stringify(symptom)
      );
      if (!isDup) {
        this.sourceList.push(symptom);
        this.sourceList = JSON.parse(JSON.stringify(this.sourceList));
      } else {
        fn.notifyError('이미 추가된 증상');
      }
    },
  },
};
</script>

<style scoped>
.symptomCode {
  font-weight: 1000;
}
.symptomName {
  padding-left: 5px;
}
</style>
