<template>
  <div id="tabContainer" class="content-block dx-card">
    <DxLoadPanel
      :position="position"
      :visible="loadingVisible"
      :show-indicator="false"
      :show-pane="false"
      :shading="true"
      :hide-on-outside-click="false"
      shading-color="rgba(0,0,0,0.3)"
    />
    <div v-if="loadingVisible" class="custom-load-message">
      신규처방 약제 DUR 요청 중....
      <span>{{ elapsedTime }}</span>
    </div>
    <DxTabs
      class="tabs"
      :data-source="tabs"
      v-model:selected-index="selectedIndex"
    />
    <div class="tabContents">
      <div v-show="selectedIndex === 0">
        <DxFileUploader
          class="fhirFileUploader"
          :ref="fileUploaderFhirRefName"
          name="file"
          :uploadHeaders="fhirUploadHeader"
          @uploaded="onUploadedFhir"
          upload-url="/api/mffu/uploadPHRFhir"
          select-button-text="나의건강기록 FHIR 파일 선택"
          :max-file-size="48000000"
          accept="application/json"
        />
        <DxForm :form-data="patientInfo" class="patientInfo">
          <DxSimpleItem data-field="name">
            <DxLabel text="이름" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="gender"
            editor-type="dxSelectBox"
            :editor-options="genderOptions"
          >
            <DxLabel text="성별" />
          </DxSimpleItem>

          <DxSimpleItem data-field="age" editor-type="dxNumberBox">
            <DxLabel text="나이" />
          </DxSimpleItem>
        </DxForm>
      </div>

      <DxDataGrid
        v-show="selectedIndex === 1"
        :ref="gridCurrentMedicationsRefName"
        :data-source="currentMedications"
        :show-borders="true"
        :show-column-lines="true"
        :keyExpr="['atcCode', 'ingredientCode']"
        :column-auto-width="true"
        width="100%"
        @row-prepared="onRowPrepared"
        @selection-changed="onSelectionChanged"
      >
        <DxToolbar>
          <DxItem
            location="before"
            locateInMenu="auto"
            template="toggleMasterDetail"
          />
          <DxItem
            location="after"
            locateInMenu="auto"
            template="observationPeriodTemplate"
          />
          <DxItem
            location="after"
            locateInMenu="auto"
            template="pdcTargetTemplate"
          />

          <DxItem
            location="after"
            locateInMenu="auto"
            template="changeSettingsTemplate"
          />
        </DxToolbar>
        <DxSorting mode="single" />
        <DxSelection show-check-boxes-mode="always" mode="multiple" />
        <DxScrolling mode="virtual" />
        <DxColumn data-field="atcCode" caption="ATC 코드" :width="100" />
        <DxColumn data-field="atcName" caption="ATC 이름" />
        <DxColumn
          data-field="ingredientCode"
          caption="주성분 코드"
          :width="100"
        />
        <DxColumn data-field="ingredientName" caption="주성분명" />
        <DxColumn
          data-field="onMedication"
          caption="현재 복용 중"
          :width="100"
          alignment="center"
          :cell-template="onMedicationTemplate"
        />
        <DxColumn
          data-field="totalDosageDays"
          caption="총 투약 일수"
          data-type="number"
          :width="130"
          sortOrder="desc"
          :cell-template="totalDosageDaysTemplate"
        />
        <DxColumn
          data-field="pdc"
          caption="PDC"
          data-type="number"
          :width="100"
        />
        <DxColumn data-field="strength" caption="함량" :width="100" />
        <DxColumn data-field="singleDosage" caption="1회 투약량" :width="100" />
        <DxColumn
          data-field="dailyDosage"
          caption="1일 투약 횟수"
          :width="100"
        />
        <template #toggleMasterDetail>
          <DxButton
            @click="toggleMasterDetail"
            :text="masterDetailToggleText"
            :icon="masterDetailToggleIcon"
            styling-mode="outlined"
          />
        </template>
        <template #observationPeriodTemplate>
          <DxNumberBox
            :value="observationPeriod"
            label="관찰기간(일)"
            :readOnly="true"
          />
        </template>

        <template #pdcTargetTemplate>
          <DxNumberBox :value="pdcTarget" label="PDC 기준" :readOnly="true" />
        </template>

        <template #changeSettingsTemplate>
          <DxButton text="변경" @click="showPopupSettings" />
        </template>

        <DxMasterDetail
          template="master-detail"
          :enabled="true"
          :auto-expand-all="true"
        />
        <template #master-detail="{ data }">
          <CurrentMedicationDetails
            :master-detail-data="filteredMedications(data)"
          />
        </template>
      </DxDataGrid>
      <NewPrescription
        v-if="selectedIndex === 2"
        :data="newPrescriptionData"
        :selection="newPrescriptionSelection"
        @setNewPrescriptionSelection="setNewPrescriptionSelection"
        @setNewPrescriptionData="setNewPrescriptionData"
      />

      <Diagnoses
        v-if="selectedIndex === 3"
        :data="diagnoses"
        :selection="diagnosesSelection"
        @setDiagnosesSelection="setDiagnosesSelection"
        @setDiagnosesData="setDiagnosesData"
      />
      <SideEffects
        v-if="selectedIndex === 4"
        :data="sideEffects"
        @setSideEffectsData="setSideEffectsData"
      />
      <ReviewSend
        v-if="selectedIndex === 5"
        :patientInfo="patientInfo"
        :currentMedication="currentMedicationsSelectionData"
        :newPrescription="newPrescriptionSelectionData"
        :diagnoses="diagnosesSelectionData"
        :sideEffects="sideEffects"
        @showLoading="showLoading"
        @hideLoading="hideLoading"
      />
    </div>
  </div>

  <DxPopup
    :ref="popupRefName"
    :visible="showPopup"
    title="설정 변경"
    :hideOnOutsideClick="true"
    showCloseButton="true"
    width="auto"
    height="auto"
  >
    <DxForm labelLocation="left" class="popup-settings-form">
      <DxSimpleItem data-field="newObservationPeriod">
        <DxLabel text="관찰기간(일)" />
        <DxNumberBox
          v-model="newObservationPeriod"
          :min="1"
          :step="1"
          format="#0"
        />
      </DxSimpleItem>
      <DxSimpleItem data-field="newPdcTarget">
        <DxLabel text="PDC 기준" />
        <DxNumberBox
          v-model="newPdcTarget"
          :min="0.1"
          :max="1"
          :step="0.1"
          format="#0.0"
        />
      </DxSimpleItem>
      <DxSimpleItem>
        <p class="popup-settings-change-notice">
          설정 값을 변경하면 기존에 체크된 항목이 초기화되며, 변경된 값을
          기준으로 다시 업데이트됩니다.
        </p>
      </DxSimpleItem>
      <DxButtonItem horizontalAlignment="center">
        <DxButtonOptions
          width="50%"
          type="default"
          text="저장"
          icon="save"
          @click="saveSettings"
        >
        </DxButtonOptions>
      </DxButtonItem>
    </DxForm>
  </DxPopup>
</template>

<script>
import { DxTabs } from 'devextreme-vue/tabs';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import {
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxButtonOptions,
  DxLabel,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxToolbar,
  DxItem,
  DxSorting,
  DxMasterDetail,
  DxSelection,
  DxScrolling,
} from 'devextreme-vue/data-grid';
import CurrentMedicationDetails from './CURRENT_MEDICATION_DETAILS.vue';
import { DxNumberBox } from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { DxPopup } from 'devextreme-vue/popup';
import NewPrescription from './NEW_PRESCRIPTION.vue';
import Diagnoses from './DIAGNOSES.vue';
import SideEffects from './SIDE_EFFECTS.vue';
import ReviewSend from './REVIEW_SEND.vue';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import fn from '@/utils/function';
import dsu from '@/utils/dataSourceUtils';

export default {
  components: {
    DxTabs,
    DxFileUploader,
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxButtonOptions,
    DxLabel,
    DxDataGrid,
    DxColumn,
    DxToolbar,
    DxItem,
    DxSorting,
    DxNumberBox,
    DxMasterDetail,
    CurrentMedicationDetails,
    DxSelection,
    DxButton,
    DxPopup,
    DxScrolling,
    NewPrescription,
    Diagnoses,
    SideEffects,
    ReviewSend,
    DxLoadPanel,
  },

  data() {
    return {
      fileUploaderFhirRefName: 'fileUploaderFhir',
      fhirUploadHeader: {
        token: dsu.getHeaders().token,
        userId: dsu.getHeaders().userId,
        fileType: 'PHR',
      },
      position: { of: '#tabContainer' },
      loadingVisible: false,
      selectedIndex: 0,
      tabs: [
        { text: '환자 정보 (FHIR 업로드)' },
        { text: '현재(=기) 복용 약제' },
        { text: '신규 처방 약제' },
        { text: '동반질환' },
        { text: '약물 부작용' },
        { text: '확인 및 전송' },
      ],
      gridCurrentMedicationsRefName: 'gridCurrentMedications',
      popupRefName: 'popupSettings',
      observationPeriod: 365,
      pdcTarget: 0.4,
      masterDetailToggleText: '모두 접기',
      masterDetailToggleIcon: 'collapse',

      showPopup: false,
      newObservationPeriod: null,
      newPdcTarget: null,
      genderOptions: {
        items: [
          { text: 'Male', value: 'Male' },
          { text: 'Female', value: 'Female' },
        ],
        displayExpr: 'text',
        valueExpr: 'value',
      },
      elapsedTime: '00:00',
      patientInfo: {
        name: '',
        age: '',
        gender: '',
      },
      medications: [],
      currentMedications: [],
      currentMedicationsSelectionData: [],
      newPrescriptionData: [],
      newPrescriptionSelection: [],
      newPrescriptionSelectionData: [],
      diagnoses: [],
      diagnosesSelection: [],
      diagnosesSelectionData: [],
      sideEffects: [],
      stopwatchTimer: null,
    };
  },
  computed: {
    grid() {
      return this.$refs[this.gridCurrentMedicationsRefName].instance;
    },
    filteredMedications() {
      return (data) => {
        return this.medications.filter(
          (medication) =>
            medication.atcCode === data.data.atcCode &&
            medication.ingredientCode === data.data.ingredientCode
        );
      };
    },
  },
  methods: {
    onUploadedFhir(e) {
      const response = JSON.parse(e.request.responseText);
      if (response.result == 'SUCCESS') {
        var data = response.data;
        this.clearData();
        this.patientInfo.name = data.name;
        this.patientInfo.age = data.age;
        this.patientInfo.gender = data.gender;
        if (this.patientInfo.age == 0) {
          this.patientInfo.age = null;
        }
        this.medications = data.medications;
        this.diagnoses = data.diagnoses;
        this.sideEffects = data.sideEffects;
        this.setCurrentMedication();
        this.setNewPrescription();
        this.setDiagnoses();
      } else {
        fn.alert(response.msg, '예외상황 발생');
      }
      e.component.reset();
    },
    clearData() {
      this.patientInfo.name = '';
      this.patientInfo.age = '';
      this.patientInfo.gender = '';
      this.medications = [];
      this.currentMedications = [];
      this.currentMedicationsSelectionData = [];
      this.newPrescriptionData = [];
      this.newPrescriptionSelection = [];
      this.newPrescriptionSelectionData = [];
      this.diagnoses = [];
      this.diagnosesSelection = [];
      this.diagnosesSelectionData = [];
      this.sideEffects = [];
    },
    setCurrentMedication() {
      this.grid.clearSelection();
      this.currentMedications = Object.values(
        this.medications.reduce((acc, medication) => {
          const key = `${medication.atcCode}-${medication.ingredientCode}`;

          if (!acc[key]) {
            acc[key] = {
              atcCode: medication.atcCode,
              atcName: medication.atcName,
              ingredientCode: medication.ingredientCode,
              ingredientName: medication.ingredientName,
              totalDosageDays: 0, // 초기화
              onMedication: '',
            };
          }

          const dispenseDate = new Date(medication.dispenseDate);
          const today = new Date();
          const daysSinceDispense = Math.floor(
            (today - dispenseDate) / (1000 * 60 * 60 * 24)
          );

          if (daysSinceDispense < medication.totalDosageDays) {
            acc[key].totalDosageDays += daysSinceDispense;
            acc[key].onMedication = '복용 중';
          } else {
            acc[key].totalDosageDays += medication.totalDosageDays;
          }

          return acc;
        }, {})
      );

      this.currentMedications.forEach((medication) => {
        medication.pdc = (
          medication.totalDosageDays / this.observationPeriod
        ).toFixed(2);
      });

      const selectedKeys = this.currentMedications
        .filter((medication) => medication.pdc >= this.pdcTarget)
        .map((medication) => ({
          atcCode: medication.atcCode,
          ingredientCode: medication.ingredientCode,
        }));

      setTimeout(() => {
        this.grid.selectRows(selectedKeys, true);
        this.grid.repaint();
      }, 1000);
    },
    onRowPrepared(e) {
      if (e.rowType == 'header') {
        e.rowElement.bgColor = '#e4edf7';
      } else if (e.rowType == 'data') {
        e.rowElement.bgColor = '#f5f5f5 ';
      }
    },
    onMedicationTemplate(cellElement, cellInfo) {
      cellElement.innerText = `${cellInfo.value}`;
      if (cellInfo.value) {
        cellElement.style.backgroundColor = '#ffeeee';
      }
    },
    totalDosageDaysTemplate(cellElement, cellInfo) {
      cellElement.innerText = `${cellInfo.value} 일`;
    },
    toggleMasterDetail() {
      if (this.masterDetailToggleIcon == 'expand') {
        this.expandAllMasterDetail();
      } else {
        this.collapseAllMasterDetail();
      }
    },
    expandAllMasterDetail() {
      this.grid.expandAll(-1);
      this.masterDetailToggleText = '모두 접기';
      this.masterDetailToggleIcon = 'collapse';
    },
    collapseAllMasterDetail() {
      this.grid.collapseAll(-1);
      this.masterDetailToggleText = '모두 펼치기';
      this.masterDetailToggleIcon = 'expand';
    },
    showPopupSettings() {
      this.newObservationPeriod = this.observationPeriod;
      this.newPdcTarget = this.pdcTarget;
      this.$refs[this.popupRefName].instance.show();
    },
    saveSettings() {
      this.observationPeriod = this.newObservationPeriod;
      this.pdcTarget = this.newPdcTarget;
      this.$refs[this.popupRefName].instance.hide();
      this.setCurrentMedication();
      this.expandAllMasterDetail();
    },
    setNewPrescription() {
      this.newPrescriptionData = JSON.parse(JSON.stringify(this.medications));
      const latestDispenseDate = this.medications.reduce(
        (latest, medication) => {
          const currentDispenseDate = new Date(medication.dispenseDate);
          return currentDispenseDate > latest ? currentDispenseDate : latest;
        },
        new Date(0)
      );
      this.newPrescriptionSelection = this.newPrescriptionData
        .filter(
          (medication) =>
            new Date(medication.dispenseDate).getTime() ===
            latestDispenseDate.getTime()
        )
        .map((medication) => ({
          dispenseDate: medication.dispenseDate,
          medicationCode: medication.medicationCode,
        }));

      this.newPrescriptionSelectionData = this.newPrescriptionData.filter(
        (medication) =>
          new Date(medication.dispenseDate).getTime() ===
          latestDispenseDate.getTime()
      );
    },
    setDiagnoses() {
      const diagnosisMap = {
        I100: {
          atcPrefixes: ['C02', 'C03', 'C07', 'C08', 'C09'],
          icdName: 'Essential (primary) Hypertension',
        },
        E100: {
          atcPrefixes: ['A10'],
          icdName: 'Type 2 diabetes mellitus',
        },
        E785: {
          atcPrefixes: ['C10'],
          icdName: 'dyslipidemia',
        },
      };

      this.medications.forEach((medication) => {
        for (const [icdCode, { atcPrefixes, icdName }] of Object.entries(
          diagnosisMap
        )) {
          if (
            atcPrefixes.some((prefix) => medication.atcCode.startsWith(prefix))
          ) {
            const existingDiagnosis = this.diagnoses.find(
              (diagnosis) => diagnosis.diagnosisCode === icdCode
            );

            if (existingDiagnosis) {
              this.diagnosesSelection.push({
                diagnosisDate: existingDiagnosis.diagnosisDate,
                diagnosisCode: icdCode,
              });
            } else {
              const newDiagnosis = {
                diagnosisType: 'ICD-10',
                diagnosisCode: icdCode,
                diagnosisCodeParent: icdCode.substring(0, 3),
                diagnosisName: icdName,
                diagnosisOrganization: '',
                department: '',
                diagnosisDate: '',
              };
              this.diagnoses.push(newDiagnosis);
              this.diagnosesSelection.push({
                diagnosisDate: '',
                diagnosisCode: icdCode,
              });
            }
          }
        }
      });

      this.$nextTick(() => {
        this.diagnosesSelectionData = this.diagnoses.filter((diagnosis) =>
          this.diagnosesSelection.some(
            (selected) =>
              selected.diagnosisDate === diagnosis.diagnosisDate &&
              selected.diagnosisCode === diagnosis.diagnosisCode
          )
        );
      });
    },
    onRowClickDiagnoses(e) {
      if (e.rowType === 'group') {
        const groupKey = e.key;
        const groupRows = this.diagnoses.filter(
          (diagnosis) => diagnosis.diagnosisCodeParent == groupKey
        );

        const keys = groupRows.map((diagnosis) => ({
          diagnosisDate: diagnosis.diagnosisDate,
          diagnosisCode: diagnosis.diagnosisCode,
        }));

        const allSelected = keys.every((key) =>
          this.gridDiagnoses.isRowSelected(key)
        );

        if (allSelected) {
          this.gridDiagnoses.deselectRows(keys);
        } else {
          this.gridDiagnoses.selectRows(keys, true);
        }
      }
    },
    onSelectionChanged(e) {
      this.currentMedicationsSelectionData = e.selectedRowsData;
    },
    setNewPrescriptionSelection(selection, data) {
      this.newPrescriptionSelection = selection;
      this.newPrescriptionSelectionData = data;
    },
    setDiagnosesSelection(selection, data) {
      this.diagnosesSelection = selection;
      this.diagnosesSelectionData = data;
    },
    setNewPrescriptionData(data) {
      this.newPrescriptionData = data;
    },
    setDiagnosesData(data) {
      this.diagnoses = data;
    },
    setSideEffectsData(data) {
      this.sideEffects = data;
    },
    showLoading() {
      this.startStopwatch();
      this.loadingVisible = true;
    },
    hideLoading() {
      this.loadingVisible = false;
      this.stopStopwatch();
    },
    startStopwatch() {
      let seconds = 0;
      this.stopwatchTimer = setInterval(() => {
        seconds++;
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        this.elapsedTime = `${minutes
          .toString()
          .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
      }, 1000);
    },
    stopStopwatch() {
      if (this.stopwatchTimer) {
        clearInterval(this.stopwatchTimer);
        this.stopwatchTimer = null;
        this.elapsedTime = '00:00';
      }
    },
  },
};
</script>

<style lang="scss">
.tabContents {
  min-height: calc(100vh - 235px);
  padding: 20px;
}
.patientInfo {
  width: 500px;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.popup-settings-form {
  width: 400px;
  margin: 20px;
}

.popup-settings-change-notice {
  color: rgb(254, 40, 40);
}

.custom-load-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
}
</style>
