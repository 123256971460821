<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />

      <DxSimpleItem
        data-field="nameLike"
        editor-type="dxTextBox"
        :editor-options="{ valueChangeEvent: 'input', onEnterKey: searchData }"
      >
        <DxLabel text="요양기관 이름" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="addressLike"
        editor-type="dxTextBox"
        :editor-options="{ valueChangeEvent: 'input', onEnterKey: searchData }"
      >
        <DxLabel text="주소" />
      </DxSimpleItem>

      <DxButtonItem
        horizontal-alignment="center"
        vertical-alignment="center"
        :buttonOptions="{ text: '조회', icon: 'search', onClick: searchData }"
      />
    </DxForm>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @exporting="onExporting"
      @editorPreparing="onEditorPreparing($event)"
      @row-inserting="onRowInserting"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multiple" />
      <DxExport :enabled="true" />
      <DxEditing
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        mode="popup"
      />

      <DxColumn type="buttons">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="code"
        caption="요양기관 코드"
        :header-filter="{ allowSearch: true }"
      >
        <DxRequiredRule />
        <DxPatternRule
          pattern="^[A-Za-z0-9-_]{1,30}$"
          message="영문 숫자 '-(대쉬)' '_(언더 바)' 로만 구성, 30자 까지 등록 가능"
        />
      </DxColumn>

      <DxColumn
        data-field="name"
        caption="요양기관 이름"
        :header-filter="{ allowSearch: true }"
      >
        <DxRequiredRule />
        <DxPatternRule pattern="^.{2,40}$" message="2~40자 까지 등록 가능" />
      </DxColumn>

      <DxColumn
        data-field="owner"
        caption="대표자 명"
        :header-filter="{ allowSearch: true }"
      >
        <DxPatternRule pattern="^.{2,20}$" message="2~10자 까지 등록 가능" />
      </DxColumn>

      <DxColumn
        data-field="bedCnt"
        caption="병상 수"
        :header-filter="{ allowSearch: true }"
        data-type="number"
        :editor-options="{ min: 0, max: 99999 }"
      >
      </DxColumn>

      <DxColumn
        data-field="medicalDepartment"
        caption="진료과목"
        :header-filter="{ allowSearch: true }"
      >
        <DxPatternRule pattern="^.{2,90}$" message="2~90자 까지 등록 가능" />
      </DxColumn>

      <DxColumn
        data-field="tel"
        caption="전화번호"
        :header-filter="{ allowSearch: true }"
      >
        <DxPatternRule pattern="^.{2,20}$" message="2~20자 까지 등록 가능" />
      </DxColumn>

      <DxColumn
        data-field="zipCode"
        caption="우편번호"
        :header-filter="{ allowSearch: true }"
      >
        <DxPatternRule pattern="^.{2,10}$" message="2~10자 까지 등록 가능" />
      </DxColumn>

      <DxColumn
        data-field="address"
        caption="주소"
        :header-filter="{ allowSearch: true }"
      >
        <DxPatternRule pattern="^.{2,100}$" message="2~100자 까지 등록 가능" />
      </DxColumn>

      <DxColumn
        data-field="grade"
        caption="협력병원 등급"
        :editorOptions="{ showClearButton: true }"
      >
        <DxLookup :data-source="['동문', '비협력', '협력병원']" />
      </DxColumn>

      <DxColumn
        data-field="type"
        caption="협력병원 구분"
        :editorOptions="{ showClearButton: true }"
      >
        <DxLookup
          :data-source="[
            '병원',
            '의원',
            '기타',
            '한방병원',
            '종합병원',
            '상급종합',
            '요양병원',
            '재활병원',
            '치과의원',
            '한의원',
          ]"
        />
      </DxColumn>

      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <History
          :master-detail-data="data"
          :url="`/api/ABIF/selectHistory`"
          :keyName="code"
          :columns="historyColumns"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxEditing,
  DxSearchPanel,
  DxRequiredRule,
  DxPatternRule,
  DxMasterDetail,
  DxLookup,
} from 'devextreme-vue/data-grid';
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxButtonItem,
} from 'devextreme-vue/form';
import History from '../_common/SIMPLE_HISTORY';
import dsu from '../../utils/dataSourceUtils';
import fn from '../../utils/function';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxEditing,
    DxSearchPanel,
    DxRequiredRule,
    DxPatternRule,
    DxMasterDetail,
    History,
    DxLookup,
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxButtonItem,
  },
  data() {
    return {
      searchFormRefName: 'searchForm',
      formData: {},
      gridRefName: 'grid',
      dataSource: dsu.getComDSGrid(
        '/api/ABIF',
        'code',
        '?code=99999999999999999',
        '요양기관 코드'
      ),
      historyColumns: [
        { dataField: 'transTime', caption: '변경 시간' },
        { dataField: 'userId', caption: '이름' },
        { dataField: 'name', caption: '병원 이름' },
        { dataField: 'owner', caption: '대표자 명' },
        { dataField: 'bedCnt', caption: '병상 수' },
        { dataField: 'medicalDepartment', caption: '진료 과목' },
        { dataField: 'tel', caption: '전화번호' },
        { dataField: 'zipCode', caption: '우편번호' },
        { dataField: 'address', caption: '주소' },
        { dataField: 'grade', caption: '협력병원 등급' },
        { dataField: 'type', caption: '협력병원 구분' },
        { dataField: 'dml', caption: 'Action' },
      ],
    };
  },
  methods: {
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    onEditorPreparing: function (e) {
      if (e.dataField == 'code') {
        e.editorOptions.inputAttr = { style: 'text-transform: uppercase' };
      }
    },
    onRowInserting(e) {
      e.data.code = e.data.code.toUpperCase();
    },
    searchData() {
      if (this.formData.nameLike || this.formData.addressLike) {
        var params = new URLSearchParams(this.formData).toString();
        params = '?' + params;

        this.$refs[this.gridRefName].instance.option(
          'dataSource',
          dsu.getComDSGrid('/api/ABIF', 'code', params, '요양기관 코드')
        );
      } else {
        fn.notifyError('기관 이름 또는 주소 입력 후 조회 필요');
      }
    },
  },
};
</script>

<style scoped>
#grid {
  height: 600px;
}
</style>
