<template>
  <DxDataGrid
    :data-source="targetList"
    :show-borders="true"
    :show-column-lines="true"
    :wordWrapEnabled="true"
  >
    <DxScrolling mode="infinite" />
    <DxSelection mode="none" />
    <DxColumn data-field="atcCode" caption="ATC 코드" width="90" />
    <DxColumn data-field="atcName" caption="ATC 이름" />
  </DxDataGrid>
</template>
<script>
import {
  DxDataGrid,
  DxSelection,
  DxScrolling,
  DxColumn,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn,
  },
  props: {
    atcData: String,
  },
  mounted() {
    this.targetList = JSON.parse(this.atcData);
  },
  data() {
    return {
      targetList: [],
    };
  },
};
</script>

<style scoped></style>
