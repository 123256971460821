<template>
  <DxDataGrid
    :ref="gridRefName"
    :data-source="dataSource"
    :show-borders="true"
    :show-column-lines="true"
    @row-prepared="onRowPrepared"
    @toolbar-preparing="onToolbarPreparing"
    @row-inserted="onDataChanged"
    @row-updated="onDataChanged"
    @row-removed="onDataChanged"
  >
    <DxSorting mode="none" />
    <DxScrolling mode="virtual" />
    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="form"
    >
    </DxEditing>
    <DxColumn type="buttons">
      <DxButton name="edit" />
    </DxColumn>
    <DxColumn data-field="sideEffect" caption="부작용 내용" sortOrder="asc">
      <DxRequiredRule message="부작용 내용 입력 필요" />
    </DxColumn>
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSorting,
  DxScrolling,
  DxEditing,
  DxRequiredRule,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSorting,
    DxScrolling,
    DxEditing,
    DxRequiredRule,
  },
  props: {
    data: Object,
  },
  data() {
    return { gridRefName: 'grid', dataSource: [] };
  },
  mounted() {
    if (this.data) {
      this.dataSource = JSON.parse(JSON.stringify(this.data));
    }
  },
  computed: {
    grid() {
      return this.$refs[this.gridRefName].instance;
    },
  },
  methods: {
    onRowPrepared(e) {
      if (e.rowType == 'header') {
        e.rowElement.bgColor = '#e4edf7';
      }
    },
    onToolbarPreparing: (e) => {
      let toolbarItems = e.toolbarOptions.items;
      toolbarItems.forEach(function (item) {
        if (item.name === 'addRowButton') {
          item.options.text = '추가';
          item.options.stylingMode = 'outlined';
          item.options.icon = 'add';
          item.showText = 'always';
        }
      });
    },
    onDataChanged() {
      this.$emit('setSideEffectsData', this.dataSource);
    },
  },
};
</script>

<style scoped></style>
