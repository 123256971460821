<template>
  <!-- 환자 정보 섹션 -->
  <div class="section">
    <div class="section-header">
      <span class="section-title-text">환자 정보</span>
    </div>
    <div class="section-content">
      <div class="patient-info">
        <div class="info-item">
          <label for="name">이름:</label>
          <input type="text" id="name" :value="patientInfo.name" readonly />
        </div>
        <div class="info-item">
          <label for="age">나이:</label>
          <input type="text" id="age" :value="patientInfo.age" readonly />
        </div>
        <div class="info-item">
          <label for="gender">성별:</label>
          <input type="text" id="gender" :value="patientInfo.gender" readonly />
        </div>
      </div>
    </div>
  </div>

  <!-- 현재 복용 약제 섹션 -->
  <div class="section">
    <div class="section-header">
      <span class="section-title-text">현재(=기) 복용 약제</span>
    </div>
    <div class="section-content">
      <DxDataGrid
        :data-source="currentMedication"
        :show-borders="true"
        :column-auto-width="true"
      >
        <DxColumn
          data-field="atcCode"
          caption="ATC 코드"
          width="100px"
          sort-order="asc"
        />
        <DxColumn data-field="atcName" caption="ATC 이름" />
        <DxColumn
          data-field="ingredientCode"
          caption="주성분 코드"
          width="100px"
        />
        <DxColumn data-field="ingredientName" caption="주성분명" />
      </DxDataGrid>
    </div>
  </div>

  <!-- 신규 처방 약제 섹션 -->
  <div class="section">
    <div class="section-header">
      <span class="section-title-text">신규 처방 약제</span>
    </div>
    <div class="section-content">
      <DxDataGrid
        :data-source="newPrescription"
        :show-borders="true"
        :column-auto-width="true"
      >
        <DxColumn
          data-field="medicationCode"
          caption="약품 코드"
          width="100px"
        />
        <DxColumn data-field="medicationName" caption="약품명" />
        <DxColumn
          data-field="atcCode"
          caption="ATC 코드"
          width="100px"
          sort-order="asc"
        />
        <DxColumn data-field="atcName" caption="ATC 이름" />
        <DxColumn data-field="ingredientCode" caption="주성분 코드" />
        <DxColumn data-field="ingredientName" caption="주성분명" />
      </DxDataGrid>
    </div>
  </div>

  <!-- 동반질환 섹션 -->
  <div class="section">
    <div class="section-header">
      <span class="section-title-text">동반질환</span>
    </div>
    <div class="section-content">
      <DxDataGrid
        :data-source="diagnoses"
        :show-borders="true"
        :column-auto-width="true"
      >
        <DxColumn
          data-field="diagnosisCode"
          caption="진단 코드"
          width="100px"
          sort-order="asc"
        />
        <DxColumn data-field="diagnosisName" caption="진단명" />
        <DxColumn
          data-field="diagnosisType"
          caption="진단 코드 종류"
          width="100px"
        />
      </DxDataGrid>
    </div>
  </div>

  <!-- 약물 부작용 섹션 -->
  <div class="section">
    <div class="section-header">
      <span class="section-title-text">약물 부작용</span>
    </div>
    <div class="section-content">
      <DxDataGrid
        :data-source="sideEffects"
        :show-borders="true"
        :column-auto-width="true"
      >
        <DxColumn
          data-field="sideEffect"
          caption="부작용 내용"
          sort-order="asc"
        />
      </DxDataGrid>
    </div>
  </div>

  <!-- 전송 버튼 -->
  <div class="action-buttons">
    <DxButton
      text="신규처방 약제 DUR"
      type="normal"
      styling-mode="outlined"
      icon="check"
      width="180"
      :disabled="loading"
      @click="sendData"
    >
    </DxButton>

    <DxButton
      text="다약제 통합조정"
      type="normal"
      styling-mode="outlined"
      icon="check"
      width="180"
      :disabled="loading"
      @click="sendDataMR"
    >
    </DxButton>
  </div>
</template>

<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import fn from '@/utils/function';
import dsu from '@/utils/dataSourceUtils';
import store from '@/store';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
  },

  data() {
    return {
      loading: false,
    };
  },
  props: {
    patientInfo: Object,
    currentMedication: Array,
    newPrescription: Array,
    diagnoses: Array,
    sideEffects: Array,
  },

  methods: {
    sendData() {
      // 환자 나이, 성별 값 입력 체크
      if (!this.patientInfo.age || !this.patientInfo.gender) {
        fn.notifyError('환자의 나이와 성별을 입력해주세요.');
        return;
      }

      // 신규 처방약제 존재 여부 체크
      if (!this.newPrescription || this.newPrescription.length === 0) {
        fn.notifyError('신규 처방약제를 입력해주세요.');
        return;
      }

      const convertedCurrentMedicationATC = this.currentMedication.map(
        (item) => ({
          atcCode: item.atcCode,
          atcName: item.atcName,
          mainItemIngr: item.ingredientName,
        })
      );

      const convertedNewPrescriptions = this.newPrescription.map((item) => ({
        kdCode: item.medicationCode,
        kdName: item.medicationName,
      }));

      const convertedDiagnoses = this.diagnoses.map((item) => ({
        diagnosisCode: item.diagnosisCode,
        diagnosisName: item.diagnosisName,
        diagnosisType: item.diagnosisType,
      }));

      var convertedSideEffects = [];
      if (this.sideEffects) {
        convertedSideEffects = this.sideEffects.map((item) => item.sideEffect);
      }

      var mgData = {
        age: this.patientInfo.age,
        gender: this.patientInfo.gender,
        currentMedicationATC: convertedCurrentMedicationATC,
        newPrescriptions: convertedNewPrescriptions,
        comorbidities: convertedDiagnoses,
        sideEffectsStr: convertedSideEffects,
        hospitalCode: store.getters.getHospitalCode,
      };

      this.loading = true;
      this.$emit('showLoading');
      dsu
        .getAxiosOBJPost('/api/DCIF/reqMGI', mgData)
        .then((res) => {
          this.loading = false;
          let response = res.data;
          this.$emit('hideLoading');
          if (response.result == 'SUCCESS') {
            fn.notifySuccess('신규처방 약제 DUR 성공적으로 생성되었습니다.');
          } else {
            fn.alert(response.msg);
          }
        })
        .catch(() => {
          this.loading = false;
          this.$emit('hideLoading');
        });
    },
    sendDataMR() {
      fn.alert('개발 중...');
    },
  },
};
</script>

<style lang="scss">
.section {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .section-header {
    margin: 0;
    padding: 10px 15px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;

    .section-title-text {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .section-content {
    padding: 15px;
  }
}

.action-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.patient-info {
  display: flex;
  gap: 20px; /* 각 항목 사이의 간격 */
}

.info-item {
  display: flex;
  flex-direction: row; /* 라벨과 입력 필드를 가로로 정렬 */
  align-items: center; /* 수직 가운데 정렬 */
}

label {
  margin-right: 10px; /* 라벨과 입력 필드 사이의 간격 */
}

input {
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}
</style>
