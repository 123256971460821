<template>
  <DxDataGrid
    :ref="gridRefName"
    :data-source="dataSource"
    :show-borders="true"
    :show-column-lines="true"
    :keyExpr="['diagnosisDate', 'diagnosisCode']"
    :grouping="{ allowCollapsing: false }"
    :groupPanel="{ visible: false }"
    :column-auto-width="true"
    @row-prepared="onRowPrepared"
    @row-click="onRowClick"
    @selection-changed="onSelectionChanged"
    @content-ready="onContentReady"
  >
    <DxSorting mode="none" />
    <DxScrolling mode="virtual" />
    <DxSelection show-check-boxes-mode="always" mode="multiple" />
    <DxToolbar>
      <DxItem
        widget="dxButton"
        location="after"
        :options="{
          icon: 'add',
          text: '추가',
          stylingMode: 'outlined',
          type: 'normal',
          onClick: showPopup,
        }"
      />
    </DxToolbar>
    <DxColumn
      data-field="diagnosisCodeParent"
      caption="그룹 코드"
      groupIndex="0"
    />

    <DxColumn data-field="diagnosisDate" caption="진단 날짜" width="100px" />
    <DxColumn
      data-field="diagnosisCode"
      caption="진단 코드"
      sortOrder="asc"
      width="100px"
    />
    <DxColumn data-field="diagnosisName" caption="진단명" />
    <DxColumn
      data-field="diagnosisType"
      caption="진단 코드 종류"
      width="100px"
    />
    <DxColumn data-field="department" caption="진단 과목" width="150px" />
    <DxColumn
      data-field="diagnosisOrganization"
      caption="진단 병원"
      width="150px"
    />
  </DxDataGrid>

  <DxPopup
    :ref="popupRefName"
    title="진단명 검색"
    width="90%"
    height="90%"
    :drag-enabled="true"
    :hideOnOutsideClick="true"
    :showTitle="true"
    :showCloseButton="true"
  >
    <div class="popup">
      <DxForm>
        <DxFormItem>
          <template #default>
            <DxLabel visible="false" />
            <div style="display: flex; align-items: center">
              <DxTextBox
                :value="searchText"
                valueChangeEvent="keyup"
                style="flex: 1; margin-right: 10px"
                @valueChanged="onSearchTextChanged"
                @enter-key="searchDiagnosis"
                :disabled="isSearching"
              />
              <DxButton
                text="검색"
                icon="search"
                type="normal"
                stylingMode="outlined"
                @click="searchDiagnosis"
                :disabled="isSearching"
              />
            </div>
          </template>
        </DxFormItem>
      </DxForm>
      <DxDataGrid
        :dataSource="searchResults"
        :show-borders="true"
        :show-column-lines="true"
        @rowClick="selectDiagnosis"
        :word-wrap-enabled="true"
        height="calc(100% - 60px)"
        width="100%"
      >
        <DxScrolling mode="virtual" />
        <DxColumn dataField="diagnosisCode" caption="진단 코드" width="100px" />
        <DxColumn
          dataField="diagnosisName"
          caption="진단명(영문)"
          sortOrder="asc"
          :word-wrap-enabled="true"
          :allow-html-inserting="true"
        />
        <DxColumn
          dataField="diagnosisNameKor"
          caption="진단명(한글)"
          sortOrder="asc"
          :word-wrap-enabled="true"
          :allow-html-inserting="true"
        />
        <DxColumn
          dataField="diagnosisType"
          caption="진단 코드 종류"
          width="100px"
        />
      </DxDataGrid>
      <DxLoadPanel
        :visible="isSearching"
        :show-indicator="true"
        :show-pane="true"
        :shading="true"
        :message="'검색 중...'"
        container=".popup"
      />
    </div>
  </DxPopup>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSorting,
  DxScrolling,
  DxSelection,
  DxToolbar,
  DxItem,
} from 'devextreme-vue/data-grid';
import DxPopup from 'devextreme-vue/popup';
import DxForm, { DxItem as DxFormItem, DxLabel } from 'devextreme-vue/form';
import DxTextBox from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import DxLoadPanel from 'devextreme-vue/load-panel';
import fn from '@/utils/function';
import dsu from '@/utils/dataSourceUtils';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSorting,
    DxScrolling,
    DxSelection,
    DxToolbar,
    DxItem,
    DxPopup,
    DxForm,
    DxFormItem,
    DxLabel,
    DxTextBox,
    DxButton,
    DxLoadPanel,
  },
  props: {
    data: Object,
    selection: Object,
  },
  data() {
    return {
      gridRefName: 'grid',
      popupRefName: 'popup',
      dataSource: [],
      popupVisible: false,
      searchText: '',
      searchResults: [],
      selectionFinal: [],
      isSearching: false,
    };
  },
  mounted() {
    this.dataSource = JSON.parse(JSON.stringify(this.data));
    this.selectionFinal = JSON.parse(JSON.stringify(this.selection));
  },
  computed: {
    grid() {
      return this.$refs[this.gridRefName].instance;
    },
    popup() {
      return this.$refs[this.popupRefName].instance;
    },
  },
  methods: {
    onSearchTextChanged(e) {
      this.searchText = e.value;
    },
    onContentReady() {
      this.grid.selectRows(this.selectionFinal, true);
    },
    onRowPrepared(e) {
      if (e.rowType == 'header') {
        e.rowElement.bgColor = '#e4edf7';
      } else if (e.rowType == 'group') {
        e.rowElement.style.backgroundColor = '#f5f5f5 ';
      }
    },
    onRowClick(e) {
      if (e.rowType === 'group') {
        const groupKey = e.key;
        const groupRows = this.dataSource.filter(
          (diagnosis) => diagnosis.diagnosisCodeParent == groupKey
        );

        const keys = groupRows.map((diagnosis) => ({
          diagnosisDate: diagnosis.diagnosisDate,
          diagnosisCode: diagnosis.diagnosisCode,
        }));

        const allSelected = keys.every((key) => this.grid.isRowSelected(key));

        if (allSelected) {
          this.grid.deselectRows(keys);
        } else {
          this.grid.selectRows(keys, true);
        }
      }
    },
    onSelectionChanged(e) {
      this.$emit(
        'setDiagnosesSelection',
        e.selectedRowKeys,
        e.selectedRowsData
      );
    },

    showPopup() {
      this.searchText = '';
      this.searchResults = [];
      this.popup.show();
    },
    searchDiagnosis() {
      if (!this.searchText || this.searchText.trim() === '') {
        fn.notifyError('검색어를 입력해주세요.');
        return;
      }

      if (this.searchText.trim().length < 2) {
        fn.notifyError('검색어는 2자 이상 입력해주세요.');
        return;
      }

      this.isSearching = true;
      this.searchResults = [];

      const params = {
        searchText: this.searchText,
      };

      dsu
        .getAxiosOBJGet('/api/MFFU/selectDiagnoses', params)
        .then((res) => {
          let response = res.data;
          if (response.result == 'SUCCESS') {
            this.searchResults = response.data;
          } else {
            fn.notifyError(response.msg);
          }
        })
        .catch((error) => {
          fn.notifyError('검색 중 오류가 발생했습니다.');
          console.error(error);
        })
        .finally(() => {
          this.isSearching = false;
        });
    },
    selectDiagnosis(e) {
      const isDuplicate = this.dataSource.some(
        (item) => item.diagnosisCode === e.data.diagnosisCode
      );

      if (isDuplicate) {
        fn.notifyError('이미 추가된 진단 코드입니다.');
        return;
      }

      const newData = {
        diagnosisDate: '',
        diagnosisCode: e.data.diagnosisCode,
        diagnosisName: e.data.diagnosisName,
        diagnosisType: e.data.diagnosisType,
        department: '',
        diagnosisOrganization: '',
        diagnosisCodeParent: e.data.diagnosisCode.substring(0, 3),
      };

      const newDataKey = {
        diagnosisDate: '',
        diagnosisCode: e.data.diagnosisCode,
      };

      this.dataSource.push(newData);
      this.selectionFinal.push(newDataKey);
      this.dataSource = JSON.parse(JSON.stringify(this.dataSource));
      this.$emit('setDiagnosesData', this.dataSource);
      this.popup.hide();
    },
  },
};
</script>

<style scoped>
.popup {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.popup DxForm {
  margin-bottom: 10px;
}
</style>
