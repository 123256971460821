<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      key-expr="ptNumber"
      :focused-row-enabled="true"
      @cellClick="onCellClick"
      @row-inserting="onRowInserting"
      @row-updating="onRowUpdating"
      @row-removing="onRowRemoving"
      @init-new-row="onInitNewRow"
      @editing-start="onEditingStart"
      @cellDblClick="onDblClick"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multiple" />

      <DxEditing
        :allow-adding="true"
        :allow-updating="true"
        :allow-deleting="true"
        mode="popup"
      >
      </DxEditing>

      <DxColumn type="buttons">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="ptName"
        caption="환자 이름"
        :header-filter="{ allowSearch: true }"
      >
        <DxRequiredRule />
        <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
      </DxColumn>

      <DxColumn
        data-field="ptNumber"
        caption="병원 등록번호"
        :header-filter="{ allowSearch: true }"
      >
        <DxRequiredRule />
        <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
      </DxColumn>

      <DxColumn
        data-field="ptRrn"
        caption="생년월일"
        :header-filter="{ allowSearch: true }"
        :editor-options="{ mask: '00000000' }"
      >
        <DxRequiredRule />
        <DxPatternRule pattern="^.{8}$" message="생년월일은 8자로 등록 필요" />
      </DxColumn>

      <DxColumn
        data-field="ptTel"
        caption="휴대폰 번호"
        :header-filter="{ allowSearch: true }"
        :editor-options="{ mask: '000-0000-0000' }"
      >
        <DxRequiredRule />
        <DxPatternRule pattern="^.{1,20}$" message="20자 까지 등록 가능" />
      </DxColumn>

      <DxColumn caption="개인정보 활용 동의">
        <DxColumn
          data-field="agreementDoc"
          data-type="string"
          caption="서면 동의"
          cellTemplate="file-cell-template"
          edit-cell-template="file-edit-template"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>

        <DxColumn
          data-field="agreementOnline"
          caption="비대면 동의"
          :allowEditing="false"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>
      </DxColumn>

      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <AdrData :ptInfo="data" />
      </template>

      <template #file-cell-template="{ data }">
        <div class="agDoc" v-if="data.data.agreementDoc">
          <img class="pdffile" :src="require('@/assets/pdf.png')" />
        </div>
      </template>

      <template #file-edit-template="{ data }">
        <div>
          <div class="uploadedFileName">
            {{ selectedAgreementDocFileName }}
            <DxButton
              v-if="selectedAgreementDocFileName"
              class="button"
              text="삭제"
              icon="remove"
              width="90"
              @click="deleteAgreementDoc(data)"
            />
          </div>
          <DxFileUploader
            :ref="fileUploaderAgreementDocRefName"
            width="400"
            name="file"
            :uploadHeaders="agreementDocUploadHeader"
            @uploaded="onUploadedAgreementDoc($event, data)"
            upload-url="/api/uploadFile"
            select-button-text="PDF 파일 선택"
            accept=".pdf"
            :max-file-size="4800000"
          />
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxHeaderFilter,
  DxSearchPanel,
  DxMasterDetail,
  DxEditing,
  DxRequiredRule,
  DxPatternRule,
} from 'devextreme-vue/data-grid';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import DxButton from 'devextreme-vue/button';
import AdrData from '../_common/ADR_DATA';
import dsu from '../../utils/dataSourceUtils';
import fn from '../../utils/function';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxSearchPanel,
    DxMasterDetail,
    AdrData,
    DxEditing,
    DxRequiredRule,
    DxPatternRule,
    DxFileUploader,
    DxButton,
  },
  data() {
    return {
      gridRefName: 'grid',
      dataSource: [],
      selectedAgreementDocFileName: '',
      fileUploaderAgreementDocRefName: 'fileUploaderAgreementDoc',
      agreementDocUploadHeader: {
        token: dsu.getHeaders().token,
        userId: dsu.getHeaders().userId,
        fileType: 'AGREEMENT_DOC',
      },
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
    fileUploaderAgreementDoc: function () {
      return this.$refs[this.fileUploaderAgreementDocRefName].instance;
    },
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    onUploadedAgreementDoc(e, data) {
      const response = JSON.parse(e.request.responseText);
      if (response.result == 'SUCCESS') {
        const sepPos = response.msg.lastIndexOf('/');
        var fileInfo = response.msg.split('/');
        let fileId = fileInfo[fileInfo.length - 1];
        this.selectedAgreementDocFileName = response.msg.substring(0, sepPos);
        data.setValue(fileId, fileId);
      } else {
        fn.notifyError('업로드 실패');
        e.component.option('uploadedMessage', '업로드 실패');
      }
      e.component.reset();
    },
    refreshData() {
      this.grid.beginCustomLoading('데이터 조회 중...');
      dsu.getAxiosOBJGet('/api/MVUR/selectPtInfo', {}).then((res) => {
        this.grid.endCustomLoading();
        let response = res.data;
        if (response.result == 'SUCCESS') {
          this.dataSource = response.data;
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    onCellClick(e) {
      if (e.column.dataField != 'agreementDoc') {
        if (e.column.type != 'detailExpand') {
          if (e.row.isExpanded) {
            e.component.collapseRow(e.key);
          } else {
            e.component.expandRow(e.key);
          }
        }
      }
    },
    onRowInserting(e) {
      dsu.getAxiosOBJPost('/api/MVUR/insertPtInfo', e.data).then((res) => {
        this.refreshData();
        let response = res.data;
        if (response.result == 'SUCCESS') {
          fn.notifySuccess(response.msg);
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    onRowUpdating(e) {
      let data = {};
      if (e.newData.ptNumber) {
        fn.notifyError('병원등록번호는 변경은 불가능합니다.');
        e.cancel = true;
        return;
      }

      Object.assign(data, e.oldData);
      Object.assign(data, e.newData);
      dsu.getAxiosOBJPost('/api/MVUR/updatePtInfo', data).then((res) => {
        this.refreshData();
        let response = res.data;
        if (response.result == 'SUCCESS') {
          fn.notifySuccess(response.msg);
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    onRowRemoving(e) {
      e.cancel = true;
      dsu.getAxiosOBJPost('/api/MVUR/deletePtInfo', e.data).then((res) => {
        this.refreshData();
        let response = res.data;
        if (response.result == 'SUCCESS') {
          fn.notifySuccess(response.msg);
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    onInitNewRow() {
      this.selectedAgreementDocFileName = '';
    },
    onEditingStart(e) {
      this.selectedAgreementDocFileName = e.data.agreementDocFileName;
    },
    onDblClick(e) {
      if (e.column.dataField == 'agreementDoc') {
        fn.downLoadFileFromId(e.value);
      }
    },
    deleteAgreementDoc(data) {
      data.setValue('');
      this.selectedAgreementDocFileName = '';
      this.fileUploaderAgreementDoc.reset();
    },
  },
};
</script>

<style lang="scss">
#grid {
  height: calc(100vh - 210px);
}

.agDoc {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .pdffile {
    width: 25px;
    height: 25px;
  }
}

.uploadedFileName {
  padding-left: 12px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: bold;
  .button {
    margin-left: 10px;
  }
}
</style>
